import React, {useState, useEffect, useRef} from "react";
import {Link, Route, Routes} from "react-router-dom";
import "./App.css";
import Advisory from "./components/Category/TabMenu/Advisory";
import CatAccordion from "./components/CatAccordion/CatAccordion";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HeroSection from "./components/HeroSection/HeroSection";
import About from "./components/NavitemsPages/About/About";
import Careers from "./components/NavitemsPages/Careers/Careers";
import Events from "./components/NavitemsPages/Events/Events";
import People from "./components/NavitemsPages/People/People";
import Press from "./components/NavitemsPages/Press/Press";
import SignUp from "./components/SignUp/SignUp";
import Stats from "./components/Tabination/Stats/Stats";
import Readmore from "./components/ReadMore_Download/Readmore";
import {useLocation} from "react-router-dom";
import IFunds from "./components/IFunds/IFunds";
import InvestmentGoal from "./components/InvestmentGoal/InvestmentGoal";
import OurMethodology from "./components/OurMethodology/OurMethodology";

function App() {
    const location = useLocation();
    const [click, setClick] = useState(false);
    const [commonClick, setCommonClick] = useState(false);
    const endRef = useRef(null);
    const params = useLocation();

    function handleclick() {
        setClick(!click);
    }

    const handleCommon = (toggle) => {
        setCommonClick(toggle);
    };

    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({behavior: "smooth"});
    };
    useEffect(scrollToBottom);

    return (
        <div className="App">
            <Header setCommonClick={handleCommon} commonClick={commonClick}/>
            <section className="section-home">
                <div className="container">
                    <div className="herocontent">
                        <div
                            className={`${["/ifunds", "/investment_goal", "/our_methodology"]?.includes(location?.pathname) ? "leftside-funds" : "leftside-common"}`}>
                            <Routes>
                                <Route path="/" element={<HeroSection/>}/>
                                <Route path="/home" element={<HeroSection/>}/>
                                <Route
                                    path="/advisory/:type"
                                    element={<Advisory title="Advisory"/>}
                                />
                                <Route path="/Advisory/stats" element={<Stats/>}/>
                                <Route
                                    path="/advisory/:type/:id"
                                    element={<Advisory title="Advisory"/>}
                                />
                                <Route
                                    path="/markets/:type"
                                    element={<Advisory title="Markets"/>}
                                />
                                <Route
                                    path="/markets/:type/:id"
                                    element={<Advisory title="Markets"/>}
                                />
                                <Route
                                    path="/investments/:type"
                                    element={<Advisory title="Investments"/>}
                                />
                                <Route
                                    path="/investments/:type/:id"
                                    element={<Advisory title="Investments"/>}
                                />
                                <Route
                                    path="/analytics/:type"
                                    element={<Advisory title="Analytics"/>}
                                />
                                <Route
                                    path="/analytics/:type/:id"
                                    element={<Advisory title="Analytics"/>}
                                />
                                <Route path="/gnii/:type" element={<Advisory title="GNII"/>}/>
                                <Route
                                    path="/gnii/:type/:id"
                                    element={<Advisory title="GNII"/>}
                                />
                                <Route path="/investnow" element={<SignUp/>}/>
                                <Route path="/ifunds" element={<IFunds/>}/>
                                <Route path="/investment_goal" element={<InvestmentGoal/>}/>
                                <Route path="/our_methodology" element={<OurMethodology/>}/>
                                {/*<Route path="/investnow" element={<SignUp />} />*/}
                                <Route path="/about" element={<About/>}/>
                                <Route path="/people" element={<People/>}/>
                                <Route path="/careers" element={<Careers/>}/>
                                <Route path="/press" element={<Press/>}/>
                                <Route path="/events" element={<Events/>}/>
                                <Route
                                    path="/advisory/:type/readmore"
                                    element={<Readmore title="Advisory"/>}
                                />
                                <Route
                                    path="/markets/:type/readmore"
                                    element={<Readmore title="Markets"/>}
                                />
                                <Route
                                    path="/investments/:type/readmore"
                                    element={<Readmore title="Investments"/>}
                                />
                                <Route
                                    path="/analytics/:type/readmore"
                                    element={<Readmore title="Analytics"/>}
                                />
                                <Route
                                    path="/gnii/:type/readmore"
                                    element={<Readmore title="GNII"/>}
                                />
                            </Routes>
                            {params.pathname === "/investnow" ||
                            params.pathname === "/ifunds" ||
                            location?.pathname === "/investment_goal" ||
                            location?.pathname === "/our_methodology" ||
                            params.pathname === "/Advisory/overview/readmore" ||
                            params.pathname === "/Markets/overview/readmore" ||
                            params.pathname === "/Investments/overview/readmore" ||
                            params.pathname === "/Analytics/overview/readmore" ||
                            params.pathname === "/GNII/overview/readmore" ? (
                                " "
                            ) : (
                                <div className="res-investnow">
                                    <Link to="/investnow" state={{visible: false}}>
                                        Get Started
                                    </Link>
                                </div>
                            )}

                            {!["/ifunds", "/investment_goal", "/our_methodology"]?.includes(location?.pathname) && (
                                <div className="c2 w-100" onClick={handleclick}>
                                    <div className="resContact-content">
                                        <div className="resfirst">
                                            <p className="m-0 fs-22 ff-regular">Contact</p>
                                            {click ? (
                                                <div className="hamburger is-active" id="hamburger">
                                                    <span className="line-res"></span>
                                                    <span className="line-res"></span>
                                                </div>
                                            ) : (
                                                <div className="hamburger">
                                                    <span className="line-res"></span>
                                                    <span className="line-res"></span>
                                                </div>
                                            )}
                                        </div>

                                        {click && (
                                            <div
                                                className={
                                                    click ? "contact-expand activeanim" : "contact-expand "
                                                }
                                            >
                                                <div className="res-cdesc">
                                                    <a
                                                        href="https://goo.gl/maps/YJEwV4GW8pXfqzhs5"
                                                        target="_blank"
                                                        className="res-location-redirect"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="fa-solid fa-location-dot"></i>
                                                        <h3>
                            <span className="d-block">
                              The Rhombus, Plot 24 Tumu Avenue
                            </span>
                                                            <span className="d-block">
                              Kanda Estates, Accra
                            </span>
                                                        </h3>
                                                    </a>
                                                </div>
                                                <div className="res-cdesc">
                                                    <h3>+233(0)559670549</h3>
                                                    <h3>+233(0)302 227 698</h3>
                                                </div>
                                                <div className="res-cdesc">
                                                    <a href="mailto: clientservices@blackstargroup.ai">
                                                        clientservices@blackstargroup.ai
                                                    </a>
                                                    <a href="mailto: info@blackstargroup.ai">
                                                        info@blackstargroup.ai
                                                    </a>
                                                </div>
                                                <div ref={endRef}></div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {!["/ifunds", "/investment_goal", "/our_methodology"]?.includes(location?.pathname) && (
                            <div className="rightside">
                                <CatAccordion setCommonClick={setCommonClick}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default App;
