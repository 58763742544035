import React, { useEffect, useState, useref } from "react";
import ham from "../../Assets/Icon/ham.svg";
import cross from "../../Assets/Icon/cross.svg";
import logo from "../../Assets/Icon/logo2.png";
import "../Header/header.scss";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

function Header({ setCommonClick, commonClick }) {
  const [mobile, setMobile] = useState(false);
  const ToggleClass = () => {
    if (window.innerWidth > 650) {
      setCommonClick(!commonClick);
    } else {
      setMobile(!mobile);
    }
  };

  return (
    <React.Fragment>
      <header className="section-header">
        <div className="container">
          <div className={commonClick ? "headernav" : "headernavheight"}>
            <div className="headerNav1">
              <div className="logo">
                <Link to="/home" className="d-inline-block">
                  <img src={logo} alt="logo icon" width={215} heigh={45} />
                </Link>
              </div>
              <div className="navbar-items">
                {/*<Link*/}
                {/*  to="/investnow"*/}
                {/*  state={{ visible: false }}*/}
                {/*  className="speakwithus fs-18 ff-regular"*/}
                {/*>*/}
                {/*  Get Started-1*/}
                {/*</Link>*/}

                {/*Hide above Link and added two new below*/}
                <Link
                    to="/investment_goal"
                    state={{ visible: false }}
                    className="speakwithus fs-18 ff-regular"
                >
                  iFunds
                </Link>
                <a
                    href="https://form.jotform.com/223174509109555"
                    target="_blank"
                    className="speakwithus fs-18 ff-regular"
                >
                  KYC/Risk
                </a>
                {commonClick ? (
                  <Link to="/home">
                    <img
                      src={cross}
                      alt="Hamburgor Icon"
                      onClick={ToggleClass}
                      className="hamIcon"
                    ></img>
                  </Link>
                ) : (
                  <Link to="/about">
                    <img
                      src={ham}
                      alt="Hamburgor Icon"
                      onClick={ToggleClass}
                      className="hamIcon"
                    ></img>
                  </Link>
                )}
              </div>
            </div>
            <CSSTransition in={commonClick} timeout={300} classNames="show">
              <div className="list-items">
                {commonClick && (
                  <ul className="list-unstyled items-list d-flex">
                    <li className="item-list">
                      <NavLink to="/about" className="item-link">
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink to="/people" className="item-link">
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink to="/careers" className="item-link">
                        Careers
                      </NavLink>
                    </li>
                    {/*<li className="item-list">*/}
                    {/*  <NavLink to="/press" className="item-link">*/}
                    {/*    Press*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="item-list">*/}
                    {/*  <NavLink to="/events" className="item-link">*/}
                    {/*    Events*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                  </ul>
                )}
              </div>
            </CSSTransition>
          </div>

          {mobile ? (
            <div className="mobileRes-nav width-auto" id="mobileres-nav">
              <div className="mobile-navitems">
                <div className="mobile-hamicon">
                  <img
                    src={cross}
                    alt="Hamburgor Icon"
                    onClick={ToggleClass}
                    className="hamIcon"
                  ></img>
                </div>

                <div className="mobile-list">
                  <ul className="list-unstyled">
                    <li className="item-list">
                      <NavLink
                        to="/about"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/people"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/careers"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Careers
                      </NavLink>
                    </li>
                    {/*<li className="item-list">*/}
                    {/*  <NavLink*/}
                    {/*    to="/press"*/}
                    {/*    className="item-link"*/}
                    {/*    onClick={() => setMobile(!mobile)}*/}
                    {/*  >*/}
                    {/*    Press*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="item-list">*/}
                    {/*  <NavLink*/}
                    {/*    to="/events"*/}
                    {/*    className="item-link"*/}
                    {/*    onClick={() => setMobile(!mobile)}*/}
                    {/*  >*/}
                    {/*    Events*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                {/*<Link*/}
                {/*  to="/investnow"*/}
                {/*  onClick={() => setMobile(!mobile)}*/}
                {/*  className="speakwithus fs-18 ff-regular"*/}
                {/*>*/}
                {/*  Get Started-2*/}
                {/*</Link>*/}

                <Link
                  to="/investment_goal"
                  onClick={() => setMobile(!mobile)}
                  className="speakwithus fs-18 ff-regular"
                >
                  iFunds
                </Link>
                <a
                    href="https://form.jotform.com/223174509109555"
                    target="_blank"
                    onClick={() => setMobile(!mobile)}
                    className="speakwithus fs-18 ff-regular"
                >
                  KYC/Risk
                </a>
              </div>
            </div>
          ) : (
            <div className="mobileRes-nav width-0" id="mobileres-nav">
              <div className="mobile-navitems">
                <div className="mobile-hamicon">
                  <img
                    src={cross}
                    alt="Hamburgor Icon"
                    onClick={ToggleClass}
                    className="hamIcon"
                  ></img>
                </div>

                <div className="mobile-list">
                  <ul className="list-unstyled">
                    <li className="item-list">
                      <NavLink
                        to="/about"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/people"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/careers"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/press"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Press
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/events"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Events
                      </NavLink>
                    </li>
                  </ul>
                </div>
                {/*<Link*/}
                {/*  to="/investnow"*/}
                {/*  onClick={() => setMobile(!mobile)}*/}
                {/*  state={{ visible: false }}*/}
                {/*  className="speakwithus fs-18 ff-regular"*/}
                {/*>*/}
                {/*  Get Started*/}
                {/*</Link>*/}
              </div>
            </div>
          )}
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
