import React, {useEffect, useState} from "react";
import "../InvestmentGoal/investment_goal.scss";
import ChevronRight from "../../Assets/right-chevron.png";
import {Link, useNavigate} from "react-router-dom";
import gt from "../../Assets/Icon/gt.svg";

const INVESTMENT_GOAL = [
    {
        name: "Prioritize Liquidity",
        description: "Hedge against potential market pitfalls and minimize volatility",
        more: ""
    },
    {
        name: "Aggressive Growth",
        description: "I want to pursue long term growth in the US stock market",
        more: ""
    },
    {
        name: "Regular Income",
        description: "I want to seek dividend and interest payment on stocks",
        more: ""
    },
    {
        name: "Balance Growth",
        description: "I want to pursue sustainable goals alongside good financial returns",
        more: ""
    },
    {
        name: "Utilize Idle Cash",
        description: "Use short-term investment strategies to maintain liquidity",
        more: ""
    },
];

function InvestmentGoal() {
    const navigate = useNavigate();
    const [fadeInAni, setFadeInAni] = useState("fade");

    useEffect(() => {
        setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
    }, []);

    return (<React.Fragment>
        <div className={`investment_goals mt-4 ${fadeInAni}`}>
            <h1>Choose your investment goal</h1>
            <div className="methodology_head d-flex">
                <p className="m-0 align-self-center" onClick={() => {
                    navigate("/our_methodology")
                }}>Our Methodology</p>
                {/*<img className="mt-1 align-self-center ms-2" src={ChevronRight} alt="chevron-right"/>*/}
            </div>
            <div className="investment_goals_card_wrapper d-flex flex-wrap text-start text-white mt-5">
                {INVESTMENT_GOAL?.map((data, index) => {
                    return (
                        <div className="investment_goals_card" key={index}>
                            <h3 className="mb-3 name">
                                {data.name}
                            </h3>
                            <p className="m-0 mb-3">
                                {data.description}
                            </p>
                            <Link className="readmore" to={`/ifunds?menu=${index + 1}`}>Read more
                                <img
                                src={gt} alt="gt icon"
                                className="gticon" width="6" height="11" />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    </React.Fragment>);
}

export default InvestmentGoal;
