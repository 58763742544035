import React from "react";
import "../Careers/careers.scss";
function Careers() {
  const CareersData = [
    {
      title: "Careers",
      category: [
        {
          subId: "experienced",
          subtitle: "Experienced Professionals",
          description: [
            {
              bullet1: "Are you looking for the next level in Your career?",
              bullet2:
                "Throughout our history, the one thing that has remained constant is Black Star’s steadfast commitment to our clients. We only win when our clients win isn’t just a tagline, it’s a mentality that underlies our relentless pursuit of excellence in everything we do at Black Star.",
              bullet3:
                "If this sounds like the approach you’ve taken in your career, then we invite you to explore global opportunities for experienced professionals at Black Star. We seek individuals who have a winning track record of delivering innovative solutions and being a trusted partner to their clients.",
              bullet4: "Write to us at info@blackstargroup.com",
            },
          ],
        },
        {
          subId: "student",
          subtitle: "Students & Graduates",
          description: [
            {
              bullet1: "Are you looking for the next level in Your career?",
              bullet2:
                "Our student and graduate employees are the foundation to our success and are heavily involved in the day-to-day workflow at Black Star. Each of our employee-partners experiences a hands-on approach to their development, with an opportunity to solve challenging and complex tasks alongside some of the best and brightest minds in the industry.",
              bullet3:
                "Black Star offers a wide range of opportunities for undergraduate and graduatestudents to gain experience in our Investment Banking, Fixed Income, Equities,Research, Wealth Management and Corporate divisions.",
              bullet4: "info@blackstargroup.com",
            },
          ],
        },
        {
          subId: "current",
          subtitle: "Current Positions",
          description: [
            {
              bullet1: "Are you looking for the next level in Your career?",
              bullet2:
                "Currently, there are no vacant positions with our institution.",

            },
          ],
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div className="section-careers">
        {CareersData?.map((values, index) => {
          return (
            <div className="careers-content" key={index}>
              <div className="careers-heading">
                <span className="horizontal-line d-block"></span>
                <h2 className="careers-title">{values.title}</h2>
              </div>
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {values?.category.map((val, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-${val.subId}`}
                        aria-expanded="false"
                        aria-controls={`flush-${val.subId}`}
                      >
                        {val.subtitle}
                      </button>
                    </h2>
                    <div
                      id={`flush-${val.subId}`}
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {val?.description.map((bullets) => (
                          <>
                            <p className="bullet1">{bullets.bullet1}</p>
                            <p>{bullets.bullet2}</p>
                            <p>{bullets.bullet3}</p>
                            <p>
                              Write to us at
                              <a
                                href="mailto:info@blackstargroup.com"
                                target="_blank"
                                className="highlight-mail"
                              >
                                {bullets.bullet4}
                              </a>
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default Careers;
