import React, { useEffect, useState } from "react";
import "../IFunds/ifunds.scss";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import {
  RadialLinearScale,
  BarElement,
  ArcElement,
  Filler,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Line, Radar } from "react-chartjs-2";
import ChevronUpForDD from "../../Assets/icons8-chevron-up-20.png";
import ChevronDownForDD from "../../Assets/icons8-chevron-down-20.png";
import ScalesIcon from "../../Assets/icons8-scales-64.png";
import Buy from "../../Assets/icons8-shopping-cart-24.png";
import GraphIcon from "../../Assets/icons8-graph-64.png";
import TreasureIcon from "../../Assets/icons8-treasure-chest-64.png";
import ShieldIcon from "../../Assets/icons8-shield-64.png";
import FocusIcon from "../../Assets/icons8-focus-64.png";
import DocIIcon from "../../Assets/icons8-document-24.png";
import ExcelIcon from "../../Assets/icons8-microsoft-excel-24.png";
import BalancedGrowth from "../../Assets/BalancedGrowthLogo.png";
import MaximizeGrowth from "../../Assets/MaximizeGrowthLogo.png";
import FocusOnIncome from "../../Assets/FocusOnIncomeLogo.png";
import ChevronUp from "../../Assets/chevron-up.png";
import ChevronDown from "../../Assets/chevron-down.png";
import UtilizeCash from "../../Assets/utilize_idle_cash.png";
import BalancedGrowthImage from "../../Assets/balanced_growth.png";
import AggressiveGrowthImage from "../../Assets/aggressive_growth.png";
import RegularIncomeImage from "../../Assets/regular_income.png";
import PrioritizeImage from "../../Assets/prioritize_liquidity.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Api } from "../../Api";
import moment from "moment";
import {
  downloadCSVFile,
  downloadPDF,
  nFormatter,
  numberWithCommas,
  numberWithCommasAndDecimal,
} from "../../Utils/helper";
import ReactSpeedometer from "react-d3-speedometer";
import BarGaugeComponent from "../BarGaugeComponent";

const FACTSHEET = [
  {
    name: "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Jun-23).pdf?CT=1693300636553&OR=ItemsView",
  },
  {
    name: "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Jun-23).pdf?CT=1693301691364&OR=ItemsView",
  },
  {
    name: "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Jun-23).pdf?CT=1693301620824&OR=ItemsView",
  },
  {
    name: "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(May-23).pdf?CT=1693202673607&OR=ItemsView",
  },
  {
    name: "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(May-23).pdf?CT=1693202696869&OR=ItemsView",
  },
];

const OVERVIEW = {
  1: {
    image: PrioritizeImage, // title: "Why FIAP?",
    // points: ["1. Seeks to provide short-term liquidity.", "2. Exposure to Foreign Money Markets.", "3. Best Performing Fixed Income Fund Over the last 3 years."],
    objective_title: "Investment Objective",
    objective_points: [
      "FIAP prioritizes liquidity while maintaining steady long-term growth. The fund invests in a diversified portfolio of global and domestic fixed-income instruments. FIAP represents our best thinking in terms of fixed income and has been the best-performing fund amongst its peers since it was launched in 2019.",
    ],
    objective_risk_title: "Investment Risk Rating",
    objective_risk_points: [
      "The fund is assessed to have a risk rating of between 2 to 3 on a scale of 1 to 6. risk ratings are reviewed quarterly and are updated accordingly",
    ],
  },
  2: {
    image: AggressiveGrowthImage, // title: "Why EEBF?",
    // points: ["1. Seeks to gain exposure to foreign equities.", "2. Aggressive Capital Appreciation.", "3. Industry Leaders Focused."],
    objective_risk_title: "Investment Risk Rating",
    objective_risk_points: [
      "The fund is assessed to have a risk rating of between 5 to 6 on a scale of 1 to 6. risk ratings are reviewed quarterly and are updated accordingly",
    ],
    objective_title: "Investment Objective",
    objective_points: [
      "EEBF taps into powerful undeniable growth trends. The fund invests in global equities with a bias towards technology. EEBF recognizes the world’s transition into the 4th industrial revolution and pays attention to sectors and companies we believe will play key roles in the future. AI, Semiconductors, cyber security, electric vehicles and companies such as Apple, Google, Microsoft, Nvidia, Tesla and more are some of the fund's core features.",
    ],
  },
  3: {
    image: RegularIncomeImage,
    objective_risk_title: "Investment Risk Rating",
    objective_risk_points: [
      "The fund is assessed to have a risk rating of between 3 to 4 on a scale of 1 to 6. risk ratings are reviewed quarterly and are updated accordingly",
    ], // title: "Why FIAP?",
    // points: ["1. Seeks to provide short-term liquidity.", "2. Exposure to Foreign Money Markets.", "3. Best Performing Fixed Income Fund Over the last 3 years."],
    objective_title: "Investment Objective",
    objective_points: [
      "Christian Community Mutual Fund aims to provide regular income for its unit holders. CCMF is an open-ended mutual fund that invests in a diversified portfolio of Bonds, Equity and Equity related instruments. The fund invests across companies with a history of paying or growing dividends. It holds domestic and global securities with a bias towards US dividend-paying stocks.",
    ],
  },
  4: {
    image: BalancedGrowthImage,
    objective_risk_title: "Investment Risk Rating",
    objective_risk_points: [
      "The fund is assessed to have a risk rating of between 3 to 4 on a scale of 1 to 6. risk ratings are reviewed quarterly and are updated accordingly",
    ], // title: "Why PBF?",
    // points: ["1. Seeks to provide short-term liquidity.", "2. Exposure to Foreign Money Markets.", "3. Best Performing Fixed Income Fund Over the last 3 years."],
    objective_title: "Investment Objective",
    objective_points: [
      "Plus Balance Fund's objective is to provide a steady capital appreciation through investment in a diversified portfolio of Bonds and Equities. The fund follows an unconstrained investment approach to identify and invest in securities where we have a high conviction of future growth.",
    ],
  },
  5: {
    // title: "Why FIAP?",
    objective_risk_title: "Investment Risk Rating",
    objective_risk_points: [
      "The fund is assessed to have a risk rating of between 1 to 2 on a scale of 1 to 6. risk ratings are reviewed quarterly and are updated accordingly",
    ],
    image: UtilizeCash, // points: ["1. Seeks to provide short-term liquidity.", "2. Exposure to Foreign Money Markets.", "3. Best Performing Fixed Income Fund Over the last 3 years."],
    objective_title: "Investment Objective",
    objective_points: [
      "Plus Income Fund aims to increase yield while maintaining liquidity. The fund is a short-term strategy that aims to pursue income at low risk. PIF invest in domestic fixed-income securities made up of government bonds, local government securities, money market, corporate bonds and cash and cash equivalent.",
    ],
  },
};

const MAIN_TITLE_LOGO = {
  1: {
    title: "Fixed Income Alpha Plus (FIAP)",
    logo: FocusOnIncome,
    color: "#d74848",
  },
  2: {
    title: "Enhanced Equity Beta Fund Plc (EEBF)",
    logo: MaximizeGrowth,
    color: "#46c5b8",
  },
  3: {
    title: "Christian Community Mutual Fund(CCMF)",
    logo: BalancedGrowth,
    color: "rgb(41 170 225)",
  },
  4: {
    title: "Balanced Fund Plc (PBF)",
    logo: "",
    color: "rgba(255,184,0,0.8)",
  },
  5: {
    title: "Income Fund Plc (PIF)",
    logo: "",
    color: "rgba(255,184,0,0.8)",
  },
};

const FEES = [
  {
    management: "1.5",
    custody_fee: "0.25",
    administrative_fee: "0",
    loads: "No loads",
  },
  {
    management: "2.5",
    custody_fee: "0.18",
    administrative_fee: "0",
    loads: "3%, 2% & 1% for 3,2,1 years",
  },
  {
    management: "2.5",
    custody_fee: "0.25",
    administrative_fee: "0",
    loads: "No loads",
  },
  {
    management: "0.75",
    custody_fee: "0.25",
    administrative_fee: "0",
    loads: "No loads",
  },
  {
    management: "0.75",
    custody_fee: "0.25",
    administrative_fee: "0",
    loads: "No loads",
  },
];

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  PointElement,
  LineElement,
  Legend,
  Filler
);

const KEYS = ["FIAP", "EEBF", "CCMF", "PBF", "PIF"];

const RISK_VALUE = [500, 900, 500, 500, 100];

const FUND_DOCUMENTS = [
  {
    name: "Fact Sheets",
    documents: [
      {
        report: "fact_sheets",
        data: [
          {
            name: "FIAP",
            yearWise: [
              {
                year: "2023",
                monthWise: [
                  {
                    month: "Jun",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Jun-23).pdf?CT=1693299007795&OR=ItemsView",
                  },
                  {
                    month: "May",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(May-23).pdf?CT=1692965881911&OR=ItemsView",
                  },
                  {
                    month: "Apr",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Apr-23).pdf?CT=1693199744940&OR=ItemsView",
                  },
                  {
                    month: "Mar",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Mar-23).pdf?CT=1693199768078&OR=ItemsView",
                  },
                  {
                    month: "Feb",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Feb-23).pdf?CT=1693199791748&OR=ItemsView",
                  },
                  {
                    month: "Jan",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2023/FIAP%20Factsheet%20(Jan-23).pdf?CT=1693199808838&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2022",
                monthWise: [
                  {
                    month: "Dec",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2022/FIAP%20Factsheet%20(Dec-22).pdf?CT=1693200168873&OR=ItemsView",
                  },
                  {
                    month: "Nov",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2022/FIAP%20Factsheet%20(Nov-22).pdf?CT=1693298192451&OR=ItemsView",
                  },
                  {
                    month: "Oct",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/FIAP/2022/FIAP%20Factsheet%20%20(Oct-22).pdf?CT=1693200207243&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "EEBF",
            yearWise: [
              {
                year: "2023",
                monthWise: [
                  {
                    month: "Jun",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Jun-23).pdf?CT=1693299166832&OR=ItemsView",
                  },
                  {
                    month: "May",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(May-23).pdf?CT=1693200362844&OR=ItemsView",
                  },
                  {
                    month: "Apr",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Apr-23).pdf?CT=1693200386084&OR=ItemsView",
                  },
                  {
                    month: "Mar",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Mar-23).pdf?CT=1693200415780&OR=ItemsView",
                  },
                  {
                    month: "Feb",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Feb-23).pdf?CT=1693200435254&OR=ItemsView",
                  },
                  {
                    month: "Jan",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2023/EEBF%20Factsheet%20(Jan-23).pdf?CT=1693200451066&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2022",
                monthWise: [
                  {
                    month: "Dec",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2022/EEBF%20Factsheet%20(Dec-22).pdf?CT=1693200480059&OR=ItemsView",
                  },
                  {
                    month: "Nov",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2022/EEBF%20Factsheet%20(Nov-22).pdf?CT=1693200497035&OR=ItemsView",
                  },
                  {
                    month: "Oct",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/EEBF/2022/EEBF%20Factsheet%20(Oct-22).pdf?CT=1693200510790&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "CCMF",
            yearWise: [
              {
                year: "2023",
                monthWise: [
                  {
                    month: "Jun",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Jun-23).pdf?CT=1693299210664&OR=ItemsView",
                  },
                  {
                    month: "May",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(May-23).pdf?CT=1693200597814&OR=ItemsView",
                  },
                  {
                    month: "Apr",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Apr-23).pdf?CT=1693200614159&OR=ItemsView",
                  },
                  {
                    month: "Mar",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Mar-23).pdf?CT=1693200638209&OR=ItemsView",
                  },
                  {
                    month: "Feb",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Feb-23).pdf?CT=1693200668445&OR=ItemsView",
                  },
                  {
                    month: "Jan",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2023/CCMF%20Factsheet%20(Jan-23).pdf?CT=1693200693245&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2022",
                monthWise: [
                  {
                    month: "Dec",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2022/CCMF%20Factsheet%20(Dec-22).pdf?CT=1693200811543&OR=ItemsView",
                  },
                  {
                    month: "Nov",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2022/CCMF%20Factsheet%20(Nov-22).pdf?CT=1693200831007&OR=ItemsView",
                  },
                  {
                    month: "Oct",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/CCMF/2022/CCMF%20Factsheet%20(Oct-22).pdf?CT=1693200847757&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "PBF",
            yearWise: [
              {
                year: "2023",
                monthWise: [
                  {
                    month: "May",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(May-23).pdf?CT=1693200999074&OR=ItemsView",
                  },
                  {
                    month: "Apr",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(Apr-23).pdf?CT=1693201032778&OR=ItemsView",
                  },
                  {
                    month: "Mar",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(Mar-23).pdf?CT=1693201336772&OR=ItemsView",
                  },
                  {
                    month: "Feb",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(Feb-23).pdf?CT=1693201476942&OR=ItemsView",
                  },
                  {
                    month: "Jan",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2023/PBF%20Factsheet%20(Jan-23).pdf?CT=1693201493679&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2022",
                monthWise: [
                  {
                    month: "Dec",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2022/PBF%20Factsheet%20(Dec-22).pdf?CT=1693201527703&OR=ItemsView",
                  },
                  {
                    month: "Nov",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2022/PBF%20Factsheet%20(Nov-22).pdf?CT=1693201544257&OR=ItemsView",
                  },
                  {
                    month: "Oct",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PBF/2022/PBF%20Factsheet%20(Oct-22).pdf?CT=1693201557933&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "PIF",
            yearWise: [
              {
                year: "2023",
                monthWise: [
                  {
                    month: "May",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(May-23).pdf?CT=1693201585470&OR=ItemsView",
                  },
                  {
                    month: "Apr",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(Apr-23).pdf?CT=1693201600655&OR=ItemsView",
                  },
                  {
                    month: "Mar",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(Mar-23).pdf?CT=1693201622421&OR=ItemsView",
                  },
                  {
                    month: "Feb",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(Feb-23).pdf?CT=1693201636310&OR=ItemsView",
                  },
                  {
                    month: "Jan",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2023/PIF%20Factsheet%20(Jan-23).pdf?CT=1693201694148&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2022",
                monthWise: [
                  {
                    month: "Dec",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2022/PIF%20Factsheet%20(Dec-22).pdf?CT=1693201726955&OR=ItemsView",
                  },
                  {
                    month: "Nov",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2022/PIF%20Factsheet%20(Nov-22).pdf?CT=1693201743156&OR=ItemsView",
                  },
                  {
                    month: "Oct",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Factsheets/PIF/2022/PIF%20Factsheet%20(Oct-22).pdf?CT=1693201759003&OR=ItemsView",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Annual Reports & Proxies",
    documents: [
      {
        report: "annual",
        data: [
          {
            name: "FIAP",
            years: [
              {
                year: "2022",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/FIAP/2022/Annual%20Report%202022.pdf?CT=1693202011089&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2021",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/FIAP/2021/Fixed%20Income%20Alpha%20Plus%20Annual%20Report%202021.pdf?CT=1693202032220&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "EEBF",
            years: [
              {
                year: "2022",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/EEBF/2022/ENHANCED%20EQUITY%20BETA%20FUND%202022.pdf?CT=1693202173760&OR=ItemsView",
                  },
                  {
                    category: "Proxy Form",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/EEBF/Proxy%20Form.pdf?CT=1693202155267&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2021",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/EEBF/2021/ENHANCED%20EQUITY%20BETA%20FUND%202021.pdf?CT=1693202204405&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "CCMF",
            years: [
              {
                year: "2021",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/CCMF/2021/Christian%20Community%20Mutual%20Fund%20Annual%20Report%202021.pdf?CT=1693202232677&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "PBF",
            years: [
              {
                year: "2022",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/PBF/2022/Plus%20Balanced%20Fund%20Annual%20Report%202022.pdf?CT=1693298248162&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2021",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/PBF/2021/Plus%20Balanced%20Fund%20Annual%20Report%202021.pdf?CT=1693202256099&OR=ItemsView",
                  },
                ],
              },
            ],
          },
          {
            name: "PIF",
            years: [
              {
                year: "2022",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/PIF/2022/Plus%20Income%20Fund%20Annual%20Report%202022.pdf?CT=1693298963819&OR=ItemsView",
                  },
                ],
              },
              {
                year: "2021",
                categeoryWise: [
                  {
                    category: "Annual Report",
                    filePath:
                      "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Annual%20Reports/PIF/2021/Plus%20Income%20Fund%20Annual%20Report%202021%20.pdf?CT=1693202331279&OR=ItemsView",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fund Particulars",
    documents: [
      {
        report: "fund_particulars",
        data: [
          {
            name: "FIAP Scheme Particular",
            filePath:
              "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Scheme%20Particulars/FIAP/FIAP%20Scheme%20Particulars.pdf?CT=1693202453518&OR=ItemsView",
          },
          {
            name: "EEBF Scheme Particular",
            filePath:
              "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Scheme%20Particulars/EEBF/EEBF%20Scheme%20Particulars.pdf?CT=1693202476215&OR=ItemsView",
          },
          {
            name: "CCMF Scheme Particular",
            filePath:
              "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Scheme%20Particulars/CCMF/CCMF%20Scheme%20Particulars.pdf?CT=1693202498063&OR=ItemsView",
          },
          {
            name: "PBF Scheme Particular",
            filePath:
              "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Scheme%20Particulars/PBF/PBF%20Scheme%20Particulars.pdf?CT=1693202524485&OR=ItemsView",
          },
          {
            name: "PIF Scheme Particular",
            filePath:
              "https://blackstaradvisorsgh-my.sharepoint.com/personal/selorm_selawoka_blackstargroup_ai/Documents/Mutual%20Funds%20For%20Website/Mutual%20Funds%20Scheme%20Particulars/PIF/PIF%20Scheme%20Particulars.pdf?CT=1693202541629&OR=ItemsView",
          },
        ],
      },
    ],
  },
];

const YearOrCategoryWiseReport = (props) => {
  const [yearWiseData, setYearWiseData] = useState({});
  const [dropdownAni, setDropdownAni] = useState("fadeUp");
  const [insideMenu, setInsideMenu] = useState(false);

  useEffect(() => {
    if (props?.documentsData) {
      setYearWiseData(props?.documentsData);
    }
  }, [props?.documentsData]);

  useEffect(() => {
    setDropdownAni(insideMenu ? "fadeDown" : "fadeUp");
  }, [insideMenu]);

  return (
    <div className={`years_months`}>
      <div
        className="year cursor d-flex justify-content-between"
        onClick={() => setInsideMenu(!insideMenu)}
      >
        <p className="fs-16">{yearWiseData.year}</p>
        <div className="align-self-center cursor">
          <img
            src={insideMenu ? ChevronUpForDD : ChevronDownForDD}
            alt="graph"
          />
        </div>
      </div>
      {props.type === "fact_sheets" &&
        yearWiseData.monthWise?.map((file, index) => {
          return (
            <div
              key={index}
              className={`fs-14 month_report d-flex justify-content-between ${dropdownAni} ${
                insideMenu ? "d-block" : "d-none"
              }`}
            >
              <p>{file.month}</p>
              <a
                className="align-self-center cursor"
                href={file.filePath}
                target="_blank"
              >
                <img src={DocIIcon} alt="graph" />
              </a>
            </div>
          );
        })}
      {props.type === "annual" &&
        yearWiseData.categeoryWise?.map((file, index) => {
          return (
            <div
              key={index}
              className={`fs-14 month_report d-flex justify-content-between ${dropdownAni} ${
                insideMenu ? "d-block" : "d-none"
              }`}
            >
              <p>{file.category}</p>
              <a
                className="align-self-center cursor"
                href={file.filePath}
                target="_blank"
              >
                <img src={DocIIcon} alt="graph" />
              </a>
            </div>
          );
        })}
    </div>
  );
};

const Reports = (props) => {
  const [reportData, setReportData] = useState({});
  const [dropdownAni, setDropdownAni] = useState("fadeUp");
  const [outerMenu, setOuterMenu] = useState(false);

  useEffect(() => {
    if (props?.data) {
      setReportData(props?.data);
    }
  }, [props?.data]);

  useEffect(() => {
    setDropdownAni(outerMenu ? "fadeDown" : "fadeUp");
  }, [outerMenu]);

  return (
    <div>
      <div
        className="doc_name cursor d-flex justify-content-between fs-18"
        onClick={() => setOuterMenu(!outerMenu)}
      >
        <p className="m-0 fw-bold">{reportData.name}</p>
        <div className="align-self-center cursor">
          <img
            src={outerMenu ? ChevronUpForDD : ChevronDownForDD}
            alt="graph"
          />
        </div>
      </div>
      {reportData?.documents?.map((doc, i) => {
        return (
          <div
            key={i}
            className={`${dropdownAni} ${outerMenu ? "d-block" : "d-none"}`}
          >
            {doc.report === "fund_particulars" && (
              <div
                className={`fs-14 month_report d-flex justify-content-between fadeDown`}
              >
                <p>{doc?.data[props.selectedMenu - 1]?.name}</p>
                <a
                  className="align-self-center cursor"
                  href={doc?.data[props.selectedMenu - 1]?.filePath}
                  target="_blank"
                >
                  <img src={DocIIcon} alt="graph" />
                </a>
              </div>
            )}
            {doc.report === "annual" && (
              <>
                {doc.data[props.selectedMenu - 1]?.years?.length > 0 ? (
                  doc.data[props.selectedMenu - 1]?.years?.map(
                    (file, index) => {
                      return (
                        <div key={index}>
                          <YearOrCategoryWiseReport
                            documentsData={file}
                            selectedMenu={props.selectedMenu}
                            type="annual"
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <div
                    className={`fs-14 month_report d-flex justify-content-between fadeDown`}
                  >
                    <p>Not available</p>
                  </div>
                )}
              </>
            )}
            {doc.report === "fact_sheets" && (
              <>
                {doc?.data[props.selectedMenu - 1]?.yearWise?.map(
                  (yearWise, ind) => {
                    return (
                      <div key={ind}>
                        <YearOrCategoryWiseReport
                          documentsData={yearWise}
                          selectedMenu={props.selectedMenu}
                          type="fact_sheets"
                        />
                      </div>
                    );
                  }
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

function IFunds() {
  const ref = React.useRef(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const activeMenu = parseInt(query.get("menu"));

  const navigate = useNavigate();
  const [selectedMenu, setMenu] = useState(activeMenu);
  const [selectedSubMenu, setSubMenu] = useState(1);
  const [fadeUpAni, setFadeUpAni] = useState("fadeUp");
  const [fadeInAni, setFadeInAni] = useState("fade");
  const [performanceTabData, setPerformanceTabData] = useState();
  const [mainSectionAndFunFactTabData, setMainSectionAndFunFactTabData] =
    useState();
  const [lineChartData, setLineChartData] = useState({});
  const [lineChart, setLineChart] = useState({});
  const [assetTypeDetails, setAssetTypeDetails] = useState({});
  const [pieChartOfAssetClassChart, setPieChartOfAssetClassChart] = useState(
    {}
  );
  const [activeTooltipInfoForPie1, setActiveTooltipInfoForPie1] =
    React.useState();
  const [spiderChartData, setSpiderChartData] = useState({});
  const [
    spiderChartForPortfolioAndBenchmark,
    setSpiderChartForPortfolioAndBenchmark,
  ] = useState({});

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, [selectedMenu]);

  useEffect(() => {
    setFadeUpAni(fadeUpAni === "fadeUp" ? "fadeUp2" : "fadeUp");
  }, [selectedMenu, selectedSubMenu]);

  useEffect(() => {
    setMenu(parseInt(activeMenu));
  }, [activeMenu]);

  const COLORS = [
    "rgba(255,255,255)",
    "rgba(255,255,255,0.8)",
    "rgba(185,185,185,0.8)",
    "rgba(115,115,115,0.8)",
    "rgba(80,80,80,0.8)",
  ];
  const RADAR_PRIMARY_COLORS = [
    "rgba(215, 72, 72, 0.2)",
    "rgba(70,197,184,0.20)",
    "rgba(41,170,225,0.20)",
    "rgba(255,206,86,0.20)",
    "rgba(255,206,86,0.20)",
  ];
  const RADAR_SECONDARY_COLORS = [
    "rgb(215, 72, 72)",
    "rgb(70,197,184)",
    "rgb(41,170,225)",
    "rgb(255,206,86)",
    "rgb(255,206,86)",
  ];

  useEffect(() => {
    if (selectedMenu) {
      Api.fundsReturn(KEYS[selectedMenu - 1]).then((response) => {
        setPerformanceTabData(response?.data);
      });

      Api.fundsDetails(KEYS[selectedMenu - 1]).then((response) => {
        setMainSectionAndFunFactTabData(response?.data);
      });

      Api.dateWiseCumulativeReturn(KEYS[selectedMenu - 1]).then((response) => {
        setLineChartData(response?.data);
      });

      Api.positionExposureByAssetTypeDetails(KEYS[selectedMenu - 1]).then(
        (response) => {
          setAssetTypeDetails(response?.data?.exposure);
        }
      );

      Api.performanceAttribute(KEYS[selectedMenu - 1]).then((response) => {
        setSpiderChartData(response?.data);
      });
    }
  }, [selectedMenu]);

  const LineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (data) {
            return parseFloat(data?.formattedValue).toFixed(2);
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
  };

  useEffect(() => {
    let dataArr = [];
    let labelsArr = [];
    if (Object?.keys(lineChartData)?.length !== 0) {
      for (const key in lineChartData) {
        dataArr.push(lineChartData[key]);
        labelsArr.push(key);
      }
    }

    const labels = labelsArr;

    const LineChartObj = {
      labels,
      datasets: [
        {
          data: dataArr,
          borderColor: MAIN_TITLE_LOGO[selectedMenu]?.color,
          backgroundColor: MAIN_TITLE_LOGO[selectedMenu]?.color,
        },
      ],
    };

    setLineChart(LineChartObj);
  }, [lineChartData]);

  const optionForPie1 = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return data?.dataset?.data[data?.dataIndex] + "%";
          },
        },
      },
    },
    cutout: 60,
    onHover: () => {
      const tooltip = ref.current._active.length > 0 && ref.current._active[0];
      if (typeof tooltip.index === "number") {
        const centerValue =
          pieChartOfAssetClassChart?.datasets[0]?.data[tooltip.index];
        setActiveTooltipInfoForPie1(centerValue);
      }
    },
  };

  useEffect(() => {
    //pieChartOfAssetClass
    let dataArr = [];
    let labelsArr = [];
    if (Object?.keys(assetTypeDetails || {})?.length !== 0) {
      let tempObj = {
        "Cash & Cash Equivalents": 0.0,
        "Fixed Income": 0.0,
        Equities: 0.0,
      };

      let fixedIncomeKeys = [
        "Corporate Bonds",
        "Government Bonds",
        "Municipal Bonds",
        "Government Bills",
        "Municipal Bills",
        "Fixed Deposits",
        "CIS - Fixed Income",
        "CIS - Money Money Market",
        "ETF - Currency",
        "ETF - Fixed Income",
      ];
      let equitiesKeys = [
        "Ordinary Shares",
        "Preference shares",
        "Venture Capital",
        "CIS - Balanced",
        "CIS - Equity",
        "ETF - Commodity",
        "ETF - EQUITY",
      ];
      if (assetTypeDetails.hasOwnProperty("Cash")) {
        tempObj["Cash & Cash Equivalents"] = parseFloat(
          numberWithCommasAndDecimal(assetTypeDetails["Cash"])
        );
      }

      fixedIncomeKeys?.map((key) => {
        if (assetTypeDetails.hasOwnProperty(key)) {
          tempObj["Fixed Income"] =
            parseFloat(numberWithCommasAndDecimal(tempObj["Fixed Income"])) +
            parseFloat(numberWithCommasAndDecimal(assetTypeDetails[key]));
        }
      });

      equitiesKeys?.map((key) => {
        if (assetTypeDetails.hasOwnProperty(key)) {
          tempObj["Equities"] =
            parseFloat(numberWithCommasAndDecimal(tempObj["Equities"])) +
            parseFloat(numberWithCommasAndDecimal(assetTypeDetails[key]));
        }
      });

      let finalData = tempObj;

      ["Cash & Cash Equivalents", "Fixed Income", "Equities"]?.map((key) => {
        if (finalData[key] <= 0) {
          delete finalData[key];
        }
      });

      const sortable = Object.entries(finalData)
        .sort(([, a], [, b]) => parseFloat(a) - parseFloat(b))
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      for (const key in sortable) {
        dataArr.push(numberWithCommasAndDecimal(sortable[key]));
        labelsArr.push(key);
      }

      let colors = [];
      colors = COLORS?.slice(0, dataArr?.length - 1)?.map((color) => color);
      setActiveTooltipInfoForPie1(dataArr[dataArr?.length - 1]);
      colors.push(MAIN_TITLE_LOGO[selectedMenu]?.color);
      const pieChartOfAssetClassChartObj = {
        labels: labelsArr,
        datasets: [
          {
            data: dataArr,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };
      setPieChartOfAssetClassChart(pieChartOfAssetClassChartObj);
    } else {
      setPieChartOfAssetClassChart(null);
    }
  }, [assetTypeDetails]);

  const radarOptions = {
    elements: {
      line: {
        borderWidth: 1,
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          display: false,
          color: "rgba(255,255,255,0.73)",
          min: 0, // it is for ignoring negative step.
          beginAtZero: true,
          callback: function (value, index, values) {
            if (Math.floor(value) === value) {
              return value;
            }
          },
          font: {
            size: 11,
          }, // stepSize: 1,
          backdropColor: "rgba(255,255,255,0)",
        },
        pointLabels: {
          // display: false,
          color: "#e0e0e0",
          font: {
            size: 11,
          },
        },
        grid: {
          color: "#4b4b4b",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    if (spiderChartData?.standardDeviation) {
      let portfolio = [
        spiderChartData.sortinoRatio,
        spiderChartData.mduration,
        spiderChartData.standardDeviation,
        spiderChartData.sharpeRatio,
        spiderChartData.averageGain,
        spiderChartData.averageLoss,
      ];
      let benchmark = [
        spiderChartData.benchmarkAttribute.sortinoRatio,
        spiderChartData.benchmarkAttribute.mduration,
        spiderChartData.benchmarkAttribute.standardDeviation,
        spiderChartData.benchmarkAttribute.sharpeRatio,
        spiderChartData.benchmarkAttribute.averageGain,
        spiderChartData.benchmarkAttribute.averageLoss,
      ];
      const data = {
        labels: [
          "Sortino Ratio",
          "Duration",
          "Standard Deviation",
          "Sharpe Ratio ",
          "Average Gain",
          "Average Loss",
        ],
        datasets: [
          {
            label: KEYS[selectedMenu - 1],
            backgroundColor: RADAR_PRIMARY_COLORS[selectedMenu - 1],
            pointBackgroundColor: RADAR_SECONDARY_COLORS[selectedMenu - 1],
            borderColor: "rgba(218,218,218,0.45)",
            borderWidth: 1,
            hoverPointBackgroundColor: "#fff",
            data: portfolio?.map((value) =>
              numberWithCommasAndDecimal(value, 2)
            ),
          },
          {
            label: "Benchmark",
            backgroundColor: "rgba(153, 102, 255, 0.2)",
            pointBackgroundColor: "rgba(153, 102, 255, 1)",
            borderColor: "rgba(218,218,218,0.45)",
            borderWidth: 1,
            hoverPointBackgroundColor: "#fff",
            data: benchmark?.map((value) =>
              numberWithCommasAndDecimal(value, 2)
            ),
          },
        ],
      };
      setSpiderChartForPortfolioAndBenchmark(data);
    } else {
      setSpiderChartForPortfolioAndBenchmark(null);
    }
  }, [spiderChartData]);

  const downloadExcelFile = () => {
    Api.performanceExcel(KEYS[selectedMenu - 1]).then((response) => {
      downloadCSVFile(response?.data?.data, response?.data?.meta?.file_name);
    });
  };

  const downloadPDFFile = () => {
    Api.fundParticularPDF(KEYS[selectedMenu - 1]).then((response) => {
      downloadPDF(response?.data?.data, response?.data?.meta?.file_name);
    });
  };

  return (
    <React.Fragment>
      <div className="ifunds mt-4">
        <div className={`wrapper-main-menu fade`}>
          <div className="main-menu-tabs">
            <div
              className={`tab ${selectedMenu === 1 && "active"}`}
              style={{
                borderBottom:
                  selectedMenu === 1
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
                navigate("/ifunds?menu=1");
              }}
            >
              <img src={ShieldIcon} alt="space-needle" />
              <p className="mt-3 mb-0">PRIORITIZE LIQUIDITY</p>
            </div>
            <div
              className={`tab ${selectedMenu === 2 && "active"}`}
              style={{
                borderBottom:
                  selectedMenu === 2
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
                navigate("/ifunds?menu=2");
              }}
            >
              <img src={GraphIcon} alt="graph" />
              <p className="mt-3 mb-0">AGGRESSIVE GROWTH</p>
            </div>
            <div
              className={`tab ${selectedMenu === 3 && "active"}`}
              style={{
                borderBottom:
                  selectedMenu === 3
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
                navigate("/ifunds?menu=3");
              }}
            >
              <img src={FocusIcon} alt="focus" />
              <p className="mt-3 mb-0">REGULAR INCOME</p>
            </div>
            <div
              className={`tab ${selectedMenu === 4 && "active"}`}
              style={{
                borderBottom:
                  selectedMenu === 4
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
                navigate("/ifunds?menu=4");
              }}
            >
              <img src={ScalesIcon} alt="scales" />
              <p className="mt-3 mb-0">BALANCED GROWTH</p>
            </div>
            <div
              className={`tab ${selectedMenu === 5 && "active"}`}
              style={{
                borderBottom:
                  selectedMenu === 5
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
                navigate("/ifunds?menu=5");
              }}
            >
              <img src={TreasureIcon} alt="treasure" />
              <p className="mt-3 mb-0">UTILIZE IDLE CASH</p>
            </div>
          </div>
        </div>

        <div
          className={`main-menu-content my-4 position-relative ${fadeInAni}`}
        >
          <div className="position-absolute files">
            <div className="d-flex flex-wrap">
              <div
                className="d-flex name_file_icon align-self-center me-3"
                data-tip="Buy Now"
              >
                <ReactTooltip />
                <a
                  href="https://form.jotform.com/223174509109555"
                  target="_blank"
                >
                  <img src={Buy} alt="buy" />
                </a>
              </div>
              <div className="name_file_icon me-3">
                <a
                  className="d-flex align-self-center text-decoration-none align-self-center cursor"
                  href={FACTSHEET[selectedMenu - 1].name}
                  target="_blank"
                >
                  <img src={DocIIcon} alt="graph" />
                  <p className="m-0 ms-2 text-white align-self-center">
                    Factsheet
                  </p>
                </a>
              </div>
              <div
                className="d-flex name_file_icon align-self-center me-3"
                onClick={downloadPDFFile}
              >
                <img src={DocIIcon} alt="doc" />
                <p className="m-0 ms-2 text-white align-self-center">
                  Fund Particulars
                </p>
              </div>
              <div
                className="d-flex name_file_icon align-self-center"
                onClick={downloadExcelFile}
              >
                <img src={ExcelIcon} alt="excel" />
                <p className="m-0 ms-2 text-white align-self-center">
                  Fund Performance
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div>
              {/*<h5 className="m-0">*/}
              {/*    BALANCED*/}
              {/*</h5>*/}
              <div className="title_img d-flex justify-content-between mt-1">
                <div className="d-flex my-3">
                  {MAIN_TITLE_LOGO[selectedMenu]?.logo ? (
                    <img
                      className="align-self-center"
                      src={MAIN_TITLE_LOGO[selectedMenu]?.logo}
                      alt={""}
                    />
                  ) : (
                    <p className="m-0 logo align-self-center">PLUS</p>
                  )}
                  <h3 className="align-self-center ms-3 mb-0">
                    {MAIN_TITLE_LOGO[selectedMenu]?.title}
                  </h3>
                </div>
                <p className="m-0 small text-white align-self-center fs-14">
                  as of{" "}
                  {moment(
                    mainSectionAndFunFactTabData?.updatedOn?.split("-")[2] +
                      "-" +
                      mainSectionAndFunFactTabData?.updatedOn?.split("-")[1] +
                      "-" +
                      mainSectionAndFunFactTabData?.updatedOn?.split("-")[0]
                  )?.format("DD, MMM yyyy")}
                </p>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 mb-md-3 mb-3 col-sm-6 cards col-lg-3 ff-regular">
                    <p className="m-0 text-start fs-12 light-gray">
                      Fund Price
                    </p>
                    <p className="m-0 text-start fs-22 mt-1 text-white">
                      GHS{" "}
                      {numberWithCommasAndDecimal(
                        mainSectionAndFunFactTabData?.unitPrice,
                        4
                      )}
                    </p>
                    {/*<p className="m-0 text-start fs-12 mt-2">*/}
                    {/*    NAV Range as of {moment(mainSectionAndFunFactTabData?.updatedOn?.split("-")[2] + "-" + mainSectionAndFunFactTabData?.updatedOn?.split("-")[1] + "-" + mainSectionAndFunFactTabData?.updatedOn?.split("-")[0])?.format("DD, MMM yyyy")}*/}
                    {/*</p>*/}
                    <p className="m-0 text-start fs-12 mt-1 light-gray ">
                      52 WK:{" "}
                      {numberWithCommasAndDecimal(
                        mainSectionAndFunFactTabData?.unitPrice52WeekLow,
                        4
                      )}{" "}
                      –{" "}
                      {numberWithCommasAndDecimal(
                        mainSectionAndFunFactTabData?.unitPrice52WeekHigh,
                        4
                      )}
                    </p>
                  </div>
                  <div className="col-12 mb-md-3 mb-3 col-sm-6 cards col-lg-3 ff-regular">
                    <p className="m-0 text-start fs-12 light-gray">
                      Month-till-date Performance
                    </p>
                    <div className="d-flex mt-1">
                      <p className="m-0 align-self-center text-start fs-22 text-white">
                        {numberWithCommasAndDecimal(
                          performanceTabData?.security?.mtdReturnPercentage
                        )}
                        %
                      </p>
                      <img
                        className="align-self-center"
                        src={
                          parseFloat(
                            performanceTabData?.security?.mtdReturnPercentage
                          ) > 0
                            ? ChevronUp
                            : ChevronDown
                        }
                        alt="down-arrow"
                      />
                    </div>
                    <p className="m-0 text-start fs-12 mt-1 light-gray">
                      {`${numberWithCommasAndDecimal(
                        mainSectionAndFunFactTabData?.unitPriceOneDayChangePercentage
                      )}% (1 Day Change)`}
                    </p>
                  </div>
                  <div className="col-12 mb-md-3 mb-3 col-sm-6 cards col-lg-3 ff-regular">
                    <p className="m-0 text-start fs-12 light-gray">
                      Year-to-date Performance
                    </p>
                    <div className="d-flex mt-1">
                      <p className="m-0 align-self-center text-start fs-22 text-white">
                        {numberWithCommasAndDecimal(
                          mainSectionAndFunFactTabData?.unitPriceYTDChangePercentage
                        )}
                        %
                      </p>
                      <img
                        className="align-self-center"
                        src={
                          parseFloat(
                            mainSectionAndFunFactTabData?.unitPriceYTDChangePercentage
                          ) > 0
                            ? ChevronUp
                            : ChevronDown
                        }
                        alt="up-arrow"
                      />
                    </div>
                    <p className="m-0 text-start fs-12 mt-1 light-gray ">
                      {`${numberWithCommasAndDecimal(
                        performanceTabData?.benchmark?.ytdReturnPercentage
                      )}% (Benchmark Performance)`}
                    </p>
                  </div>
                  <div className="col-12 mb-md-3 mb-3 col-sm-6 cards col-lg-3 ff-regular">
                    <p className="m-0 text-start fs-12 light-gray">
                      Hypothetical growth of GHS 10,000
                    </p>
                    <p className="m-0 text-start fs-22 mt-1  text-white">
                      GHS{" "}
                      {mainSectionAndFunFactTabData?.cumulativeReturnPercentage
                        ? numberWithCommas(
                            (10000 *
                              mainSectionAndFunFactTabData?.cumulativeReturnPercentage) /
                              100 +
                              10000
                          )
                        : "━━"}
                    </p>
                    <p className="m-0 text-start fs-12 mt-1 light-gray">
                      {mainSectionAndFunFactTabData?.cumulativeReturnPercentage
                        ? numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.cumulativeReturnPercentage
                          ) + "% (Return since inception)"
                        : "━━"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper-sub-menu">
          <div className="main-sub-menu-tab">
            <div
              className={`sub-tab ${selectedSubMenu === 1 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 1
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(1);
              }}
            >
              <p className="my-1">Overview</p>
            </div>
            <div
              className={`sub-tab ${selectedSubMenu === 2 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 2
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(2);
              }}
            >
              <p className="my-1">Performance</p>
            </div>
            <div
              className={`sub-tab ${selectedSubMenu === 3 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 3
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(3);
              }}
            >
              <p className="my-1">Fund facts</p>
            </div>
            <div
              className={`sub-tab ${selectedSubMenu === 4 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 4
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(4);
              }}
            >
              <p className="my-1">Allocation & Metrics</p>
            </div>
            <div
              className={`sub-tab ${selectedSubMenu === 5 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 5
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(5);
              }}
            >
              <p className="my-1">Fees</p>
            </div>
            <div
              className={`sub-tab ${selectedSubMenu === 6 && "active"}`}
              style={{
                borderBottom:
                  selectedSubMenu === 6
                    ? `3px solid ${MAIN_TITLE_LOGO[selectedMenu]?.color}`
                    : "",
              }}
              onClick={() => {
                setSubMenu(6);
              }}
            >
              <p className="my-1">Fund Documents</p>
            </div>
          </div>
        </div>

        <div className="sub-menu-content my-4">
          {selectedSubMenu === 1 && (
            <div className={`overview row text-start text-white ${fadeUpAni}`}>
              <div className="col-md-5 col-lg-3 col-12">
                <p className="text-white fs-18">
                  {OVERVIEW[selectedMenu]?.objective_title}
                </p>
                {OVERVIEW[selectedMenu]?.objective_points?.map(
                  (line, index) => {
                    return (
                      <p className="fs-16 ff-regular" key={index}>
                        {line}
                      </p>
                    );
                  }
                )}

                <p className="text-white fs-18">
                  {OVERVIEW[selectedMenu]?.objective_risk_title}
                </p>
                {OVERVIEW[selectedMenu]?.objective_risk_points?.map(
                  (line, index) => {
                    return (
                      <p className="fs-16 ff-regular" key={index}>
                        {line}
                      </p>
                    );
                  }
                )}
              </div>
              <div className="col-md-7 BarGaugeComponent col-lg-5 col-12 mt-md-0 mt-3">
                <p className="text-white fs-18 text-center">
                  Target Fund Allocation
                </p>
                {/*<img className="img-fluid text-center" src={OVERVIEW[selectedMenu]?.image}/>*/}
                <BarGaugeComponent id={selectedMenu} />
              </div>
              <div className="col-md-6 col-lg-4 col-12 img text-center  mt-md-0 mt-3">
                <p className="text-white fs-18 text-center">Fund Risk Rating</p>
                <div className="img-fluid mt-5 ReactSpeedometer">
                  <ReactSpeedometer
                    width={270}
                    needleHeightRatio={0.7}
                    value={RISK_VALUE[selectedMenu - 1]}
                    segmentColors={[
                      "#75ff00",
                      "#bdff5b",
                      "#fff532",
                      "#ff974d",
                      "#ff2700",
                    ]}
                    currentValueText="Risk Level"
                    customSegmentLabels={[
                      {
                        text: "Very Low",
                        position: "OUTSIDE",
                        color: "#ffffff",
                        fontSize: "10px",
                      },
                      {
                        text: "Low",
                        position: "OUTSIDE",
                        color: "#ffffff",
                        fontSize: "10px",
                      },
                      {
                        text: "Moderate",
                        position: "OUTSIDE",
                        color: "#ffffff",
                        fontSize: "12px",
                      },
                      {
                        text: "High",
                        position: "OUTSIDE",
                        color: "#ffffff",
                        fontSize: "10px",
                      },
                      {
                        text: "Very High",
                        position: "OUTSIDE",
                        color: "#ffffff",
                        fontSize: "10px",
                      },
                    ]}
                    ringWidth={20}
                    needleTransitionDuration={3333}
                    needleTransition="easeElastic"
                    needleColor={"#dbeff3"}
                    textColor={"#d8dee9"}
                  />
                </div>
              </div>
              <p className="text-white fs-12 mt-4 text-start">
                The fund’s holdings may drift away from target allocation
                depending on market conditions for short periods of time as the
                Fund manager rebalances or repositions the fund periodically,
                The risk rating will also be reviewed periodically depending
                market conditions and security allocation.
              </p>
            </div>
          )}

          {selectedSubMenu === 2 && (
            <div className="row performance justify-content-between">
              <div className={`col-lg-7 col-md-6 col-12 ${fadeUpAni}`}>
                <p className="text-white text-start fs-18">
                  Performance Return (%)
                </p>
                <p className="m-0 fs-14 ff-regular text-start text-white">
                  As of{" "}
                  {moment(
                    performanceTabData?.updatedOn?.split("-")[2] +
                      "-" +
                      performanceTabData?.updatedOn?.split("-")[1] +
                      "-" +
                      performanceTabData?.updatedOn?.split("-")[0]
                  )?.format("DD, MMM yyyy")}
                </p>
                <div className="table-responsive">
                  <table className="table text-white table-borderless fs-12 mt-3">
                    <thead>
                      <tr className="text-end">
                        <th scope="col" className="text-start">
                          Return(%)
                        </th>
                        <th scope="col">YTD</th>
                        <th scope="col">1M</th>
                        <th scope="col">3M</th>
                        <th scope="col">6M</th>
                        <th scope="col">1Y</th>
                        <th scope="col">3Y</th>
                        <th scope="col">Life</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          className="text-start"
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                        >
                          {KEYS[selectedMenu - 1]}
                        </th>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security?.ytdReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.oneMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.threeMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.sixMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.oneYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.threeYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td
                          style={{
                            color: MAIN_TITLE_LOGO[selectedMenu]?.color,
                          }}
                          className="text-end"
                        >
                          {numberWithCommasAndDecimal(
                            performanceTabData?.security
                              ?.inceptionReturnPercentage
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Benchmark
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark?.ytdReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.oneMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.threeMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.sixMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.oneYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.threeYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.benchmark
                              ?.inceptionReturnPercentage
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Peers
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.ytdReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.oneMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.threeMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.sixMonthReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.oneYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.threeYearReturnPercentage
                          ) || "━━"}
                        </td>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            performanceTabData?.peer?.inceptionReturnPercentage
                          ) || "━━"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="m-0 fs-12 text-start text-white">
                  Past performance is not indicative of future results. The
                  performance quoted represents past performance and does not
                  guarantee future results. Investment return and principal will
                  fluctuate so that an investor's shares, when sold or redeemed,
                  may be worth more or less than the original cost. Current
                  performance may be lower or higher than the performance quoted
                  depending on when you entered the fund.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-md-0 mt-4 mb-md-0 mb-4">
                <p className="text-white text-start fs-18">
                  Cumulative Return (%)
                </p>
                <div className="line-chart">
                  <Line options={LineChartOptions} data={lineChart} />
                  <div className="axis fs-12">
                    <p>{lineChart?.labels[0]}</p>
                    <p>
                      {
                        lineChart?.labels[
                          parseInt((lineChart?.labels?.length - 1) / 2)
                        ]
                      }
                    </p>
                    <p>{lineChart?.labels[lineChart?.labels?.length - 1]}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedSubMenu === 3 && (
            <div
              className={`row fun-facts justify-content-between ${fadeUpAni}`}
            >
              <div className="col-md-5 col-12">
                <p className="m-0 fs-18 text-start text-white">Key Facts</p>
                <div className="table-responsive">
                  <table className="table text-white table-borderless fs-12 mt-3">
                    <tbody>
                      <tr>
                        <th scope="row" className="text-start">
                          Net Assets of Funds (GHS)
                        </th>
                        <td className="text-end">
                          {nFormatter(mainSectionAndFunFactTabData?.nav)}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Valuation Methodology</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {mainSectionAndFunFactTabData?.valuationStrategy ===
                          "MARKET_VALUE"
                            ? "Mark to Market"
                            : mainSectionAndFunFactTabData?.valuationStrategy ===
                              "AMORTIZED_VALUE"
                            ? "Amortiation"
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Benchmark Index
                        </th>
                        <td className="text-end">
                          {mainSectionAndFunFactTabData?.benchmarkName}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Fund Inception
                        </th>
                        <td className="text-end">
                          {moment(
                            mainSectionAndFunFactTabData?.issueDate?.split(
                              "-"
                            )[2] +
                              "-" +
                              mainSectionAndFunFactTabData?.issueDate?.split(
                                "-"
                              )[1] +
                              "-" +
                              mainSectionAndFunFactTabData?.issueDate?.split(
                                "-"
                              )[0]
                          )?.format("DD, MMM yyyy")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Asset Class
                        </th>
                        <td className="text-end">
                          {mainSectionAndFunFactTabData?.cisType?.replaceAll(
                            "_",
                            " "
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-5 col-12 mt-md-0 mt-4">
                <p className="m-0 fs-18 text-start text-white">
                  Portfolio Characteristics
                </p>
                <div className="table-responsive">
                  <table className="table text-white table-borderless fs-12 mt-3">
                    <tbody>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Closing Price (GHS)</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.unitPrice,
                            4
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Turnover Rate (%)</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.turnover
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Standard Deviation (%)</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.standardDeviation
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Average Gain (%)</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.averageGain
                          ) || "━━"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          <p className="m-0">Average Loss (%)</p>
                          {/*<p className="m-0 small">as of 15 Nov, 22</p>*/}
                        </th>
                        <td className="text-end">
                          {numberWithCommasAndDecimal(
                            mainSectionAndFunFactTabData?.averageLoss
                          ) || "--"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {selectedSubMenu === 4 && (
            <div className="asset_allocation text-start">
              <div className="main-wrapper">
                <div className="wrapper-1">
                  <p className="m-0 fs-18 text-start text-white">
                    Allocation, Risk & Benchmarking
                  </p>
                  <p className="fs-14 text-white mt-4">
                    As of Scheme Particulars
                  </p>
                  <div className="text-white fs-14">
                    <p>
                      Investment Risk Rating The standard against which the
                      performance of a mutual fund is measured is referred to as
                      a benchmark.
                    </p>
                    <p>
                      Benchmarks are a handy tool for useful comparison as the
                      benchmark returns are indicative of how much your fund has
                      earned against how much it should have earned.
                    </p>
                    <p>
                      The fund manager’s target is to beat its benchmark over a
                      period of time.
                    </p>
                  </div>
                </div>
                <div className="wrapper-2">
                  <p className="m-0 fs-18 text-start text-white">
                    Current Allocation
                  </p>
                  {pieChartOfAssetClassChart?.labels?.length > 0 ? (
                    <div className="d-md-flex">
                      <div className="chart">
                        <div className="center-text text-white">
                          {activeTooltipInfoForPie1}%
                        </div>
                        <Doughnut
                          data={pieChartOfAssetClassChart}
                          ref={ref}
                          options={optionForPie1}
                        />
                      </div>
                      <div className="d-flex align-self-center labels">
                        <div className="color">
                          {pieChartOfAssetClassChart?.datasets[0]?.backgroundColor?.map(
                            (color, index) => {
                              return (
                                <p style={{ background: color }} key={index} />
                              );
                            }
                          )}
                        </div>
                        <div className="names">
                          {pieChartOfAssetClassChart?.labels?.map(
                            (label, index) => {
                              return (
                                <p
                                  className="m-0 ff-regular text-white"
                                  key={index}
                                >
                                  {label}
                                </p>
                              );
                            }
                          )}
                        </div>
                        <div className="values">
                          {pieChartOfAssetClassChart?.datasets[0]?.data?.map(
                            (value, index) => {
                              return (
                                <p
                                  className="m-0 ff-regular text-white"
                                  key={index}
                                >
                                  {value}%
                                </p>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-white fs-12 mx-2">
                      No data to plot chart.
                    </p>
                  )}
                </div>
                <div className="wrapper-3">
                  <p className="m-0 fs-18 text-start text-white">
                    Risk Metrics
                  </p>
                  {spiderChartForPortfolioAndBenchmark?.labels?.length > 0 ? (
                    <div className="radar-chart-wrapper">
                      <div className="radar-chart">
                        <Radar
                          data={spiderChartForPortfolioAndBenchmark}
                          options={radarOptions}
                        />
                      </div>
                      <div className="d-flex align-self-center labels">
                        <div className="names">
                          <p className="m-0 ff-regular text-white fw-bolder">
                            Metric
                          </p>
                          {spiderChartForPortfolioAndBenchmark?.labels?.map(
                            (label, index) => {
                              return (
                                <p
                                  className="m-0 ff-regular text-white"
                                  key={index}
                                >
                                  {label}
                                </p>
                              );
                            }
                          )}
                        </div>
                        <div className="values">
                          <div className="d-flex">
                            <p
                              className="m-0 box-color"
                              style={{
                                background:
                                  RADAR_SECONDARY_COLORS[selectedMenu - 1],
                              }}
                            />
                            <p
                              className="box-value m-0 ff-regular fw-bolder"
                              style={{
                                color: RADAR_SECONDARY_COLORS[selectedMenu - 1],
                              }}
                            >
                              {KEYS[selectedMenu - 1]}
                            </p>
                          </div>
                          {spiderChartForPortfolioAndBenchmark?.datasets[0]?.data?.map(
                            (value, index) => {
                              return (
                                <p
                                  className="m-0 ff-regular fw-bolder"
                                  style={{
                                    color:
                                      RADAR_SECONDARY_COLORS[selectedMenu - 1],
                                  }}
                                  key={index}
                                >
                                  {value}%
                                </p>
                              );
                            }
                          )}
                        </div>
                        <div className="values">
                          <div className="d-flex justify-content-end">
                            <p
                              className="m-0 box-color"
                              style={{ background: "rgba(153, 102, 255, 1)" }}
                            />
                            <p className="box-value m-0 ff-regular text-white fw-bolder">
                              Benchmark
                            </p>
                          </div>
                          {spiderChartForPortfolioAndBenchmark?.datasets[1]?.data?.map(
                            (value, index) => {
                              return (
                                <p
                                  className="m-0 ff-regular text-white"
                                  key={index}
                                >
                                  {value}%
                                </p>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-white fs-12 mx-2">
                      No data to plot chart.
                    </p>
                  )}
                </div>
              </div>
              <p className="text-white fs-12 mt-4 text-start">
                Past performance is not indicative of future results. The
                performance quoted represents past performance and does not
                guarantee future results. Investment return and principal will
                fluctuate so that an investor's shares, when sold or redeemed,
                may be worth more or less than the original cost. Current
                performance may be lower or higher than the performance quoted
                depending on when you entered the fund.
              </p>
            </div>
          )}

          {selectedSubMenu === 5 && (
            <div className={`fees row ${fadeUpAni}`}>
              <div className="col-md-6 col-lg-5 col-12">
                <p className="m-0 fs-18 text-start text-white">Fees</p>
                <div className="table-responsive">
                  <table className="table text-white table-borderless fs-12 mt-3">
                    <tbody>
                      <tr>
                        <th scope="row" className="text-start">
                          Management Fee (%)
                        </th>
                        <td className="text-end">
                          {FEES[selectedMenu - 1]?.management}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Custody Fee (%)
                        </th>
                        <td className="text-end">
                          {FEES[selectedMenu - 1]?.custody_fee}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Administrative Fee (%)
                        </th>
                        <td className="text-end">
                          {FEES[selectedMenu - 1]?.administrative_fee}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="text-start">
                          Loads
                        </th>
                        <td className="text-end">
                          {FEES[selectedMenu - 1]?.loads}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {selectedSubMenu === 6 && (
            <div
              className={`fund_documents text-start text-white mt-4 mb-5 ${fadeUpAni}`}
            >
              <div className="row">
                {FUND_DOCUMENTS?.map((data, index) => {
                  return (
                    <div key={index} className="col-md-4 col-12">
                      <Reports data={data} selectedMenu={selectedMenu} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default IFunds;
