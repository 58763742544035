import React from 'react';
import {
    BarGauge, Label, Export, Legend, Tooltip
} from 'devextreme-react/bar-gauge';
import {Font} from "devextreme-react/chart";

const values = [
    {
        data: [95.00, 50.00, 30.00, 25.00, 50.00],
        labels: ['Bond and bond equivalent', 'Money market security', 'Local govt. & stat. agency', 'REITF' , 'Cash and cash equivalent']
    },
    {
        data: [40.00, 90.00, 15.00],
        labels: ['Bonds', 'Equities and equity-related instruments', 'Cash and cash equivalent']
    },
    {
        data: [95.00, 90.00, 50.00],
        labels: ['Bonds', 'Equities and equity-related instruments', 'Cash and cash equivalent']
    },
    {
        data: [80.00, 40.00, 30.00, 35.00, 50.00, 50.00, 10.00],
        labels: ['Government bonds', 'Local govt. securities', 'Money market securities', 'Corporate bonds', 'Cash and cash equivalent', 'Listed equities', 'Unlisted equities']
    },
    {
        data: [90.00, 50.00, 30.00, 30.00, 50.00],
        labels: ['Government bonds', 'Local govt. securities', 'Money market (Treasury bills, fixed deposits & commercial papers)', 'Corporate bonds', 'Cash and cash equivalent' ]
    },
];

const format = {
    type: 'fixedPoint',
    precision: 1,
};

const BarGaugeComponent = ({id}) => {

    function getText(item, text) {
        return `${(values[id - 1]?.labels[item.index])}`;
    }

    function getText2(item, text) {
        return `${(values[id - 1]?.labels[item.index])} - ${text}%`;
    }

    const customizeText = ({valueText}) => {
        return `${valueText} %`;
    }
    const customizeLegend = (arg) => {
        return getText(arg.item, arg.text);
    }

    const getInitialSidebarShow = () => {
        if (window.innerWidth < 620) {
            return true;
        } else {
            return false;
        }
    };

    const customizeTooltip = (arg) => {
        return {
            text: getText2(arg, arg.valueText),
        };
    }


    return (
        <>
            {id && (
                <BarGauge
                    width={getInitialSidebarShow() ? 300 : 500}
                    id="gauge"
                    startValue={0}
                    endValue={100}
                    // backgroundColor={"#000000"}
                    values={values[id - 1]?.data}
                >
                    <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                    <Label indent={20} format={format} customizeText={customizeText}/>
                    <Export enabled={false}/>
                    <Legend visible={true} customizeText={customizeLegend} verticalAlignment="bottom" horizontalAlignment="center" >
                        <Font size={11} color={"#ffffff"} />
                    </Legend>
                </BarGauge>
            )}
        </>
    );
}

export default BarGaugeComponent;
